import nprogress from 'nprogress'
import router from '@/router'
import 'nprogress/nprogress.css'

nprogress.configure({ showSpinner: false })
// 白名单
// const whiteRouteList = [
//   '/login',
//   '/home',
//   '/404',
//   '/pythonEditor',
// ]

// function isWhiteRoute(targetPath: string): boolean {
//   return whiteRouteList.some(item => new RegExp(`^${item}($|/)`).test(targetPath))
// }
// 全局前置守卫
router.beforeEach(async (to, from, next) => {
  document.title = `牛图图-${to.meta.title}`
  nprogress.start()
  next()
  // const token = GET_TOKEN()
  // if (token) {
  //   if (to.path === '/login')
  //     next({ path: '/' })
  //   else
  //     next()
  // }
  // else {
  //   if (isWhiteRoute(to.path))
  //     next()
  //   else
  //     next({ path: '/login', query: { redirect: to.path } })
  // }
  // if (from.path === '/redirect') {
  //   window.location.reload()
  // }
})

// 全局后置守卫
router.afterEach(() => {
  nprogress.done()
})
